/*
 * 업무구분: 고객
 * 화 면 명: MSPFS103M
 * 화면설명: 고객별보유계좌조회2600
 * 작 성 일: 2023.03.27
 * 작 성 자: 이태흥
 */
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">

    <!-- header start -->
    <fs-header
      ref="fsHeader"
      :propObj="pHeaderObj"
    ></fs-header>
    <!-- header end -->

    <!-- main start -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top gap40">
          <div class="left column mr0">
            <div class="row w100p gap40">
              <div class="wrap-input row">
                <label> 고객번호 </label>
                <mo-text-field 
                  ref="ed_cust_no"
                  class="mw150"
                  :class="lv_ed_cust_no.error ? 'error' : ''"                  
                  v-model="lv_ed_cust_no.value" 
                  maxlength="7"
                  @keyup="fn_SearchValid($event)"
                  @input="fn_clear('appointCust')"
                  type="number"
                />
                <mo-button class="w61" @click="fn_OpenPopup(0)"> 고객정보 </mo-button>
                <mo-text-field 
                  class="w130" 
                  v-model="lv_ed_cust_nm.value" 
                  disabled
                />
              </div>
              <div class="wrap-input row">
                <label class="emphasis"> 계좌상태 </label>
                <!-- <mo-radio 
                  v-model="lv_accn_state_cd.value" 
                  class="flex-unset mw-auto mr14"
                > 정상 </mo-radio>
                <mo-radio 
                  v-model="lv_accn_state_cd.value" 
                  class="flex-unset mw-auto"
                > 전체 </mo-radio> -->
                <mo-radio-wrapper 
                  :items="accnState" 
                  v-model="lv_accn_state_cd.value" 
                  class="row"
                />
              </div>
            </div>
          </div>
          <div class="right">
            <div class="row w100p">
              <div class="wrap-button row">
                <mo-button class="clear" @click="fn_Init()"> 초기화 </mo-button>
                <mo-button primary class="inquiry" @click="fn_CustRetAccnSearch()"> 조회 </mo-button>
              </div>
            </div>
          </div>
        
        </div>
      </ur-box-container>

      <!-- fs-cust-info start -->
      <fs-cust-info
        ref="fsCustInfo"
        :propObj="pCustInfoObj"
        @fs-cust-name="fn_SetEdCustName"
      ></fs-cust-info>
      <!-- fs-cust-info end -->

      <ur-box-container>
        <div class="wrap-table-title row">
          <h2 class="table-title">
            고객구분
          </h2>
          <span> {{ lv_cust_gb_nm }} </span>
          <span class="red ml20"> {{ lv_accd_nm }} </span>
        </div>
        <div class="right-bg-white">
          <div class="wrap-table x-scroll h-scroll">
            <table class="table row-type th-wsN">
              <thead>
                <tr>
                  <th class="w74 sticky left0"> 관리점 </th>
                  <th class="wsN sticky left74 w140"> 계좌번호 </th>
                  <th class="sticky left214"> 펀드<br/>코드 </th>
                  <th> 펀드명 </th>
                  <th> 주문상태 </th>
                  <th> 판매수수료 </th>
                  <th> 잔고좌수 </th>
                  <th> 투자금액 </th>
                  <th> 평가금액(세전) </th>
                  <th> 세전수익률(%) </th>
                  <th> 세제상품<br/>유형 </th>
                  <th> 약정이체<br/>등록 </th>
                  <th> 계좌신규일 </th>
                  <th> 계좌만기일 </th>
                  <th> CMS/대체/<br/>급여이체만기일 </th>
                  <th> 최종거래일 </th>
                  <th> 관리자 </th>
                  <th> 권유자 </th>
                  <th class="w50"> 계좌<br/>상태 </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(items, i) in lv_accn_list" :key="i" class="wsN">
                  <td class="sticky left0"> {{ items.bfcer_fof_abbr_nm }} </td>
                  <td class="sticky left74"> {{ items.bfcer_accn_no }} </td>
                  <td class="sticky left214"> {{ items.bfcer_fnd_cd }} </td>
                  <td class="aL"> {{ items.bfcer_fnd_abbr_nm }} </td>
                  <td> {{ items.buy_state_nm }}</td>
                  <td class="aR"> {{ $bizUtil.numberWithCommas(items.sale_coms_aggr_amt) }} </td>
                  <td class="aR"> {{ $bizUtil.numberWithCommas(items.bfcer_blc) }}</td>
                  <td class="aR"> {{ $bizUtil.numberWithCommas(items.orign_amt) }} </td>
                  <td class="aR">
                    <!-- <mo-text-field mask="number" guide-message="" /> -->
                    {{ $bizUtil.numberWithCommas(items.eval_amt) }}
                  </td>
                  <td class="aR"> {{ items.prof_rt }} </td>
                  <td> {{ items.bfcer_taxsy_typ_dtl_sc_nm }} </td>
                  <td> 
                    <mo-button 
                      class="button-text clickE" 
                      :id="'con_trans_yn_tooltip_' + i"
                    > {{ items.con_trans_yn }}  </mo-button>

                    <mo-tooltip 
                      :target-id="'con_trans_yn_tooltip_' + i"
                      status="white" 
                      class="fts-tooltip">

                      <p class="fts-tooltip-tit">약정이체목록</p>
                      <div class="wrap-table mt14">
                        <table class="table row-type">
                          <thead>
                            <th class="w68">은행명</th>
                            <th>은행계좌번호</th>
                            <th>예금주명</th>
                          </thead>
                          <tbody>
                            <tr v-for="(tooltip, j) in lv_tooltip_list" :key="j">
                              <td> {{ tooltip.trsf_bfcer_bnk_nm }} </td>
                              <td> {{ tooltip.bnk_accn_no_encr }} </td>
                              <td> {{ tooltip.trsf_accn_dpstr_nm }} </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </mo-tooltip>
                  </td>
                  <td> {{ $bizUtil.dateDashFormat(items.opng_ymd) }} </td>
                  <td> {{ $bizUtil.dateDashFormat(items.exp_ymd) }}</td>
                  <td> 
                    <!-- {{ $bizUtil.dateDashFormat(items.trsf_knd_clby_exp_ymd) }} -->

                    <mo-button v-if="items.trsf_knd_clby_exp_ymd != ''"
                      class="button-text clickE" 
                      :id="'trsf_knd_clby_exp_ymd_tooltip_' + i"
                    > {{ $bizUtil.dateDashFormat(items.trsf_knd_clby_exp_ymd) }}  </mo-button>

                    <mo-tooltip v-if="items.trsf_knd_clby_exp_ymd != ''"
                      :target-id="'trsf_knd_clby_exp_ymd_tooltip_' + i"
                      status="white" 
                      class="fts-tooltip">

                      <p class="fts-tooltip-tit">이체정보</p>
                      <div class="wrap-table mt14">
                        <table class="table row-type">
                          <tbody>
                            <tr>
                              <td class="aL"> 자동이체종류 </td>
                              <td class="aL"> {{ items.trsf_sc_nm }} </td>                                  
                            </tr>                                
                            <tr>
                              <td class="aL"> 자동이체금액 </td>
                              <td class="aR"> {{ items.trsf_amt }} 원 </td>
                            </tr>
                            <tr>
                              <td class="aL"> 자동이체일 </td>
                              <td class="aL"> {{ items.trsf_dd }} </td>
                            </tr>
                            <tr>
                              <td class="aL"> 자동이체기간 </td>
                              <td class="aL"> {{ $bizUtil.dateDashFormat(items.trsf_knd_clby_exp_ymd) }} ~ {{ $bizUtil.dateDashFormat(items.trsf_knd_clby_str_ymd) }}</td>
                            </tr>
                            <tr>
                              <td class="aL"> 저축만기일 </td>
                              <td class="aL"> {{ $bizUtil.dateDashFormat(items.exp_ymd) }} </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </mo-tooltip>

                  </td> <!-- CMS/대체/급여이체만기일 -->
                  <td> {{ $bizUtil.dateDashFormat(items.lst_rdamt_ymd) }}</td>
                  <td> {{ items.mngr_nm }}</td>
                  <td> {{ items.cnvsr_nm }} </td>
                  <td> {{ items.bfcer_accn_stat_nm }} </td>
                  <td> </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td class="sticky left0"> 합계 </td>
                  <td class="sticky left74 aR"> {{ lv_accn_list.length + '건'}} </td>
                  <td class="sticky left214"> </td>
                  <td> </td>
                  <td> </td>
                  <td class="aR"> {{ lv_total_accn_list.total_sale_coms_aggr_amt }} </td>
                  <td class="aR"> {{ lv_total_accn_list.total_bfcer_blc }} </td>
                  <td class="aR"> {{ lv_total_accn_list.total_orign_amt }} </td>
                  <td class="aR"> {{ lv_total_accn_list.total_eval_amt }} </td>
                  <td class="aR"> {{ lv_total_accn_list.total_prof_rt }}</td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </ur-box-container>

      <!-- <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large"> 처리 </mo-button>
        </div>
      </ur-box-container> -->
    </main>
    <!-- main end -->


    <!-- alert modal popup -->
    <fs-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>

    <!-- popup308 -->
    <msp-fs-308p
      ref="popup308"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup308Obj"
    ></msp-fs-308p>


  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
  import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
  import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
  
  import FSHeader from '~/src/ui/fs/comm/FSHeader'          // header 영역 (공통)
  import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup'  // Alert 팝업 (공통)
  import FSCustInfo from '~/src/ui/fs/comm/FSCustInfo'      // 고객정보 (공통)

  import MSPFS308P from '~/src/ui/fs/MSPFS308P'  // 고객정보 팝업


  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS103M",
    screenId: "MSPFS103M",
    components: {
      'fs-header': FSHeader,
      'fs-alert-popup': FSAlertPopup,
      'fs-cust-info': FSCustInfo,      
      'msp-fs-308p': MSPFS308P,
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {

    },
    mounted() {
      this.fn_Init()
      this.$bizUtil.insSrnLog('MSPFS103M')
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 공통 객체
        pHeaderObj: {
          title: '고객별보유계좌조회',
          step: '',
        },
        pAlertPopupObj: {},
        lv_commCode: {},
        pCustInfoObj: {},

        isMobile: window.vue.getStore('deviceState').getters.getIsMobile,


        // 팝업 객체
        pPopup308Obj: {},

        // 검색조건
        lv_ed_cust_no: {},        // 고객번호
        lv_accn_state_cd: {},     // 계좌상태
        lv_ed_cust_nm: {},        // 고객명

        // 기본정보
        lv_accn_list: [],         // 계좌정보 list
        lv_total_accn_list: {},   // 합계 계좌정보
        lv_tooltip_list: [],      // tooltip 정보 list

        lv_cust_gb_nm: '',        // 고객구분 text
        lv_accd_nm: '',           // 사고압류구분 text

        // 고객정보 펼치기/접기
        isActive: false,


        /***********************************************************************************
         * F10570138 : 고객별보유계좌조회
         * F10570167 : 개인정보조회
         ***********************************************************************************/
        // EAI(PO) 호출 객체
        eaiCommObj: FSCommUtil.gfn_eaiCommObj(),

      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {

      // 계좌상태
      accnState() { 
        let rtn = [];
        rtn.push({value: '1', text: '정상'});
        rtn.push({value: '2', text: '전체'});
        return rtn;
      },

    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {

    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {

      /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult
       * 설명       : 공통코드 세팅
       ******************************************************************************/
      fn_SetCommCode() {
        console.log('fn_SetCommCode...')

        let params = []
        FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
      },

      fn_SetCommCodeResult(pResult) {
        this.lv_commCode = pResult
      },


      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
      fn_Init() {
        console.log('[MSPFS103M] fn_Init...')

        this.lv_ed_cust_no          = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_cust_no')      // 고객번호
        this.lv_accn_state_cd       = FSCommUtil.gfn_dataSet(0, '1', false, '', 'accn_state_cd')  // 계좌상태
        this.lv_ed_cust_nm          = FSCommUtil.gfn_dataSet(1)                                   // 고객명

        this.lv_accn_list           = []    // 계좌정보 list
        this.lv_total_accn_list     = {}    // 합계 계좌정보
        this.lv_tooltip_list        = []    // tooltip 정보 list

        this.lv_cust_gb_nm          = ''    // 고객구분 text
        this.lv_accd_nm             = ''    // 사고압류구분 text

        // 고객정보 초기화
        this.$refs.fsCustInfo.fn_Init()

      },


      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
         if( !FSCommUtil.gfn_isNull(pPopupObj) ) {
          // 공통 얼럿 팝업 오브젝트 초기화
          FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
        }
        this.$refs.alertPopup.fn_Open()
      },

      /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       * 타입(type) : 0: 고객정보
       ******************************************************************************/
      fn_OpenPopup(type) {
        switch (type) {
          case 0:
            this.$refs.popup308.fn_Open()
            break          
          case 1:

            break
        }
      },


      /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback
       * 타입(type) : 0: 고객정보
       ******************************************************************************/
      fn_Popup_CallBack(type, pData) {
        switch (type) {
          case 0:
            console.log('고객정보 callback...')

            // 고객번호 매핑 후 고객정보조회
            this.lv_ed_cust_no.value = pData
            this.fn_SearchValid({key: 'v'})
            break
         }
      },

      /******************************************************************************
       * Function명 : fn_IsActive
       * 설명       : 고객정보 펼치기/접기
       ******************************************************************************/
      fn_IsActive() {
        this.isActive = !this.isActive;
      },


      /******************************************************************************
       * Function명 : fn_CustInfo, fn_SetEdCustName
       * 설명       : 고객정보조회
       ******************************************************************************/
      fn_CustInfo() {
        console.log('fn_CustInfo.....')

        this.pCustInfoObj.custNo = this.lv_ed_cust_no.value
        this.$refs.fsCustInfo.fn_CustInfoSearch()
      },

      fn_SetEdCustName(val) {
        // 고객명
        this.lv_ed_cust_nm.value = val
      },



      /******************************************************************************
       * Function명 : fn_CustRetAccnSearch, fn_CustRetAccnSearchResult, fn_CustRetAccnSearchClear
       * 설명       : 고객별보유계좌조회
       ******************************************************************************/
      fn_CustRetAccnSearch() {
        console.log('fn_CustRetAccnSearch.....')

        // 고객별보유계좌조회 결과 초기화
        this.fn_CustRetAccnSearchClear()

        this.lv_ed_cust_no.error = false
        if( FSCommUtil.gfn_isNull(this.lv_ed_cust_no.value) ) {
          FSCommUtil.gfn_validate(this, '고객번호를 입력해 주십시오.')
          this.lv_ed_cust_no.error = true
          this.$refs['ed_cust_no'].focus()
          return          
        } else if( FSCommUtil.gfn_length(this.lv_ed_cust_no.value) != 7 || !FSCommUtil.gfn_isNum(this.lv_ed_cust_no.value) ) {
          FSCommUtil.gfn_validate(this, '고객번호 형식에 맞지 않습니다.')
          this.lv_ed_cust_no.error = true
          this.$refs['ed_cust_no'].focus()
          return
        }

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570138_S'
        this.eaiCommObj.params = {
          bfcer_cust_no : this.lv_ed_cust_no.value,     // 수익증권고객번호
          prcs_sc_cd    : 'S1',                         // 프로세스구분코드
          // accn_state_cd : this.lv_accn_state_cd.value,  // 계좌상태(정상:1 전체:2)
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CustRetAccnSearchResult)
      },

      fn_CustRetAccnSearchResult(pResultData) {
        console.log('fn_CustRetAccnSearchResult.....')

        // 고객별보유계좌조회 결과 초기화
        this.fn_CustRetAccnSearchClear()

        let t_popupObj = {}
        let t_data = pResultData.data
        console.log(t_data)

        // 정상 조회 = 809900, 데이터 없음 = 809990
        if( t_data.error_msg == '809900' ) {
          
          // 계좌번호 치환
          t_data.rp260000_O_01VO.forEach((item, idx) => {
            if(item.bfcer_accn_no.length >= 8) { //계좌번호가 8자리 이상인 경우
              item.bfcer_accn_no = item.bfcer_accn_no.substr(0, 3) + "*****" + item.bfcer_accn_no.substr(8)
              item.bfcer_accn_no = FSCommUtil.gfn_replace_accn_no(item.bfcer_accn_no)
            } 
            if (item.bfcer_accn_no.length < 8 && item.bfcer_accn_no.length > 0) { //계좌번호가 8자리 미만인 경우
              item.bfcer_accn_no = item.bfcer_accn_no.substr(0, 3) + '*'.repeat(item.bfcer_accn_no.length-3)
            }
          })
          
          // 약정이체 계좌번호 치환           
          t_data.rp260000_O_03VO.forEach((item, idx) => {  
            if(item.bnk_accn_no_encr.length >= 8) { //계좌번호가 8자리 이상인 경우
              item.bnk_accn_no_encr = item.bnk_accn_no_encr.substr(0, 3) + "*****" + item.bnk_accn_no_encr.substr(8)
            } 
            if (item.bnk_accn_no_encr.length < 8 && item.bnk_accn_no_encr.length > 0) { //계좌번호가 8자리 미만인 경우
              item.bnk_accn_no_encr = item.bnk_accn_no_encr.substr(0, 3) + '*'.repeat(item.bnk_accn_no_encr.length-3)
            }
          })

          // 계좌상태 선택값 확인(정상:1, 전체:2)
          if( this.lv_accn_state_cd.value == '1' ) {
            t_data.rp260000_O_01VO.forEach((item, idx) => {
              // 정상인 데이터만 배열에 추가
              if( item.bfcer_accn_stat_cd == '11' ) {
                this.lv_accn_list.push(item)
              }
            })
          } else {
            this.lv_accn_list = t_data.rp260000_O_01VO
          }


          // 합계 구하기
          let total_sale_coms_aggr_amt = 0  // 판매수수료
          let total_bfcer_blc = 0           // 잔고좌수
          let total_orign_amt = 0           // 투자금
          let total_eval_amt = 0            // 평가금액

          this.lv_accn_list.forEach((item, idx) => {
            total_sale_coms_aggr_amt += Number(item.sale_coms_aggr_amt)
            total_bfcer_blc += Number(item.bfcer_blc)
            total_orign_amt += Number(item.orign_amt)
            total_eval_amt += Number(item.eval_amt)
          })

          this.lv_total_accn_list.total_sale_coms_aggr_amt = this.$bizUtil.numberWithCommas(total_sale_coms_aggr_amt) + '원'
          this.lv_total_accn_list.total_bfcer_blc = this.$bizUtil.numberWithCommas(total_bfcer_blc) + '좌'
          this.lv_total_accn_list.total_orign_amt = this.$bizUtil.numberWithCommas(total_orign_amt) + '원'
          this.lv_total_accn_list.total_eval_amt = this.$bizUtil.numberWithCommas(total_eval_amt) + '원'

          // 평가금액 - 투자금
          this.lv_total_accn_list.total_prof_rt = this.$bizUtil.numberWithCommas(total_eval_amt - total_orign_amt) + '원'


          // tooltip 정보 list 매핑
          this.lv_tooltip_list = t_data.rp260000_O_03VO

          // 고객구분 text 매핑
          this.lv_cust_gb_nm = FSCommUtil.gfn_isNull(t_data.cust_gb) ? '' : t_data.cust_gb

          // 사고압류구분명 값 확인 - 고객구분 값 오른쪽에 표시(빨간색 text)
          // t_data.accd_nm = '11111'          
          this.lv_accd_nm = FSCommUtil.gfn_isNull(t_data.accd_nm) ? '' : '사망+압류+질권 등록 있음'

        } else if ( t_data.error_msg == '809990' ) {
          t_popupObj = {content: "조회 결과가 없습니다."}
          this.fn_AlertPopup(0, t_popupObj)

        } else {
          t_popupObj = {content: "고객별보유계좌조회에 실패하였습니다."}
          this.fn_AlertPopup(0, t_popupObj)

        }
      },

      fn_CustRetAccnSearchClear() {
        // 검색 결과 초기화
        this.lv_accn_list           = []    // 계좌정보 list
        this.lv_total_accn_list     = {}    // 합계 계좌정보
        this.lv_tooltip_list        = []    // tooltip 정보 list
        this.lv_cust_gb_nm          = ''    // 고객구분 text
        this.lv_accd_nm             = ''    // 사고압류구분 text
      },


      /******************************************************************************
       * Function명 : fn_SearchValid
       * 설명       : 필수값 검증 후 조회
       ******************************************************************************/
      fn_SearchValid(event) {
        console.log('fn_SearchValid.....')

        if( FSCommUtil.gfn_length(this.lv_ed_cust_no.value) == 7 ) {
          this.lv_ed_cust_no.error = false
          
          if(!FSCommUtil.gfn_isNum(this.lv_ed_cust_no.value) ) {
            FSCommUtil.gfn_validate(this, '고객번호 형식에 맞지 않습니다.')
            this.lv_ed_cust_no.error = true
            this.$refs['ed_cust_no'].focus()
            return 

          } else {
            if( FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || this.isMobile ) {
              // 고객정보조회
              this.fn_CustInfo()
            }
          }
        } else if( event.key == 'Delete' || event.key == 'Backspace' ) {
          // 고객명 초기화
          this.lv_ed_cust_nm.value = ''

          // 고객정보 펼치기 정보 초기화
          this.$refs.fsCustInfo.fn_Init()
        }

      },
      
      /******************************************************************************
       * Function명 : fn_clear
       * 설명       : validation  초기화
       ******************************************************************************/
      fn_clear(param) {
        switch(param) {
          case 'appointCust' :
            this.lv_ed_cust_no.error = false // 고객번호 validation 초기화
            break
        }
      }

    }
  }
</script>
<style scoped>
</style>